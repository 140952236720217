.adm-list-body {
  background-color: transparent;
}

.adm-form {
  --border-top: none !important; /* 移除上边框 */
  --border-bottom: none !important; /* 移除下边框 */
  --border-inner: none !important;
}

.adm-input-element {
  color: #ffffff !important;
}

/* .adm-form-item {
  border-top: 1px solid #666666 !important;
} */
.adm-list-item-content-main {
  padding: 6px 0 !important;
}

.adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
  background-color: #eaa31b;
}
