/* styles.module.css */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .ipadButtonContainer {
    top: 60vh !important;
  }

  .ipadButtonWarp{
    top: 50vh !important;
  }

  .ipadButton {
    max-width: 220px !important;
  }

  .ipadH5Button {
    top: 65vh !important;
  }
}
