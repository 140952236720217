.extraPart {
  border-left: solid 1px #eeeeee;
  padding-left: 12px;
  font-size: 17px;
  line-height: 22px;
}
.eye {
  padding: 4px;
  cursor: pointer;
  svg {
    display: block;
    font-size: var(--adm-font-size-7);
  }
}
.adm-input::placeholder {
  color: #666666;
}